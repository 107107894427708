import React from 'react';
import styled from 'styled-components';

export const SecondaryText = styled(({ tag = 'span', children, ...props }) =>
  React.createElement(tag, props, children),
)`
  color: ${(props) => props.theme.lydia.colors.text.secondary};
`;

export const PrimaryText = styled(({ tag = 'span', children, ...props }) =>
  React.createElement(tag, props, children),
)`
  color: ${(props) => props.theme.lydia.colors.primary};
`;

export const CenterAlignText = styled(({ tag = 'p', children, ...props }) =>
  React.createElement(tag, props, children),
)`
  text-align: center;
`;

export const RightAlignText = styled(({ tag = 'p', children, ...props }) =>
  React.createElement(tag, props, children),
)`
  text-align: right;
`;

const Label = styled.label`
  color: ${(props) => props.theme.lydia.colors.text.dark};
`;

const PrimaryHeading = styled(PrimaryText)`
  display: flex;
  align-items: center;
`;

export const SecondaryHeading = styled(PrimaryHeading)`
  color: ${(props) => props.theme.lydia.colors.secondary};
`;

export const LightHeading = styled(PrimaryHeading)`
  color: ${(props) => props.theme.lydia.colors.text.light};
  font-size: 28px;
`;

export const Heading = styled(SecondaryText)`
  font-size: ${(props) => props.fontSize}px;
  color: var(--color-neutral-400);
`;

Heading.defaultProps = {
  fontSize: 14,
};

export const DarkHeading = styled(Heading)`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 500;
  color: var(--color-neutral-800);
`;

DarkHeading.defaultProps = {
  fontSize: 14,
};

export const CollapseHeading = styled(Label)`
  font-size: ${(props) => props.fontSize}px;
  color: var(--color-neutral-500);
  font-weight: bold;
`;

CollapseHeading.defaultProps = {
  fontSize: 14,
};
