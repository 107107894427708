import React from 'react';
import { Loader } from '@refrens/disco';

import { EmptyStateLoaderWrapper } from './styles';

const EmptyStateLoader: React.FC = () => {
  return (
    <EmptyStateLoaderWrapper>
      <Loader position='static' size={64} color='var(--color-primary-500)' />
    </EmptyStateLoaderWrapper>
  );
};

export default EmptyStateLoader;
